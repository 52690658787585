@import url(//db.onlinewebfonts.com/c/ce84c94c656759caafe1c408c8cdd90d?family=Pavement);
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,500;1,700&display=swap');


@font-face {font-family: "Pavement"; src: url("//db.onlinewebfonts.com/t/ce84c94c656759caafe1c408c8cdd90d.eot"); 
  src: url("//db.onlinewebfonts.com/t/ce84c94c656759caafe1c408c8cdd90d.eot?#iefix") format("embedded-opentype"), 
  url("//db.onlinewebfonts.com/t/ce84c94c656759caafe1c408c8cdd90d.woff2") 
  format("woff2"), url("//db.onlinewebfonts.com/t/ce84c94c656759caafe1c408c8cdd90d.woff") 
  format("woff"), url("//db.onlinewebfonts.com/t/ce84c94c656759caafe1c408c8cdd90d.ttf") format("truetype"), 
  url("//db.onlinewebfonts.com/t/ce84c94c656759caafe1c408c8cdd90d.svg#Pavement") format("svg"); }

  @font-face {
    font-family: "Turnpike";
    src: local("Turnpike"),
     url("./fonts/turnpike-webfont.ttf") format("truetype");
    font-weight: bold;
    }

    html, body {
      background: url("../public/images/BlueBackground.jpg") repeat-x center center fixed;
      -webkit-background-size: 100%;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      }

  h1, h2, h3, h4, h5, h6, .nav-link, nav {
    font-family: "Turnpike", sans-serif;
    color: hsla(230, 34%, 25%, 1);

    color: linear-gradient(90deg, hsla(230, 34%, 25%, 1) 26%, hsla(218, 14%, 38%, 1) 90%);
    
    color: -moz-linear-gradient(90deg, hsla(230, 34%, 25%, 1) 26%, hsla(218, 14%, 38%, 1) 90%);
    
    color: -webkit-linear-gradient(90deg, hsla(230, 34%, 25%, 1) 26%, hsla(218, 14%, 38%, 1) 90%);
    text-shadow: 2px 2px 2px rgb(190, 170, 140);
  }

  .nav-link {
    font-size: 2em;
  }

  .nav-item {
    color:rgb(42,49,86);
  }

  a:link{
    text-decoration: none;
    color:#1D253F;
    
  }

  a:visited{
    text-decoration: none;
    color:#1D253F;
  }

  a:hover {
    font-weight: bold;
    color: #1D253F;
  }

 h4, h5, h6, p {
  margin: 0;
  font-family: "WorkSans-Bold", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.custom-toggler {
  position: right;
  align-self: right;
  margin-top: 10px;
  text-align: right;
}

.clip{
  color: hsla(230, 34%, 25%, 1);

  color: linear-gradient(90deg, hsla(230, 34%, 25%, 1) 26%, hsla(218, 14%, 38%, 1) 90%);
  
  color: -moz-linear-gradient(90deg, hsla(230, 34%, 25%, 1) 26%, hsla(218, 14%, 38%, 1) 90%);
  
  color: -webkit-linear-gradient(90deg, hsla(230, 34%, 25%, 1) 26%, hsla(218, 14%, 38%, 1) 90%);
  
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#2A3156", endColorstr="#545E6F", GradientType=1 );
font-size: 3vw;
text-align: center;

}

.cliptwo{
color: hsla(230, 34%, 25%, 1);
font-size: 1.5vw;
font-family: "Turnpike", sans-serif;
text-align: center;
text-shadow: #b38977 2px 2px 2px;
}

.imagetext {
  color: hsla(230, 34%, 25%, 1);
  color: linear-gradient(90deg, hsla(230, 34%, 25%, 1) 26%, hsla(218, 14%, 38%, 1) 90%);
  
  color: -moz-linear-gradient(90deg, hsla(230, 34%, 25%, 1) 26%, hsla(218, 14%, 38%, 1) 90%);
  
  color: -webkit-linear-gradient(90deg, hsla(230, 34%, 25%, 1) 26%, hsla(218, 14%, 38%, 1) 90%);
  
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#2A3156", endColorstr="#545E6F", GradientType=1 );
  font-size: 1vw;
  font-family: "Turnpike", sans-serif;
  text-shadow: #b38977 2px 2px 2px;
  text-align: center;
}

.preorder {
  position: relative;
  animation: mymove 3s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;;
  align-items: left;
  margin-left: 40%;
}

@keyframes mymove {
  from {transform: rotate(0deg);}
  to {transform: rotate(359deg);}
}

.btn-primary {
  background: #1D253F;
  border: #b38977 2px solid;
  color: #fffFFF;
}